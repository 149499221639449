<template>
    <div class="card">
        <span>账户管理</span>
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    methods: {},
    mounted() {},
}
</script>

<style scoped>
</style>
